import { Controller } from '@hotwired/stimulus'
import { i18n } from 'i18n'

export default class extends Controller {
  static get targets () {
    return ['income', 'assets', 'commitments', 'worth', 'percentage', 'maxInvestment', 'text']
  }

  connect () {
    this.percentageTarget.innerHTML = this.currencyFormatter.format(0)

    if (this.hasMaxInvestmentTarget) {
      this.maxInvestmentTarget.innerHTML = i18n.t('affordability.based-on', { amount: this._nearestLowerThousand(0) })
    }
  }

  get currencyFormatter () {
    return new Intl.NumberFormat(document.documentElement.lang, {
      currency: 'EUR',
      maximumFractionDigits: 0,
      style: 'currency'
    })
  }

  get numberFormatter () {
    return new Intl.NumberFormat(document.documentElement.lang, {
      currency: 'EUR',
      maximumFractionDigits: 0
    })
  }

  get incomeValue () {
    return this._formatValue(this.incomeTarget.autoNumeric.rawValue)
  }

  get assetsValue () {
    return this._formatValue(this.assetsTarget.autoNumeric.rawValue)
  }

  get commitmentsValue () {
    return this._formatValue(this.commitmentsTarget.autoNumeric.rawValue)
  }

  calculate () {
    const result = this.incomeValue + this.assetsValue - this.commitmentsValue

    this.worthTarget.innerHTML = this.numberFormatter.format(result)

    const tenPercent = result * 0.1
    const fivePercent = result * 0.05

    this.percentageTarget.innerHTML = this.currencyFormatter.format(tenPercent)

    if (this.hasMaxInvestmentTarget) {
      this.maxInvestmentTarget.innerHTML = i18n.t('affordability.based-on', { amount: this._nearestLowerThousand(fivePercent) })
    }

    this.toggleTextBox()
  }

  toggleTextBox () {
    if (this.incomeTarget.value && this.assetsTarget.value && this.commitmentsTarget.value) {
      this.textTarget.classList.remove('hidden')
    } else {
      this.textTarget.classList.add('hidden')
    }
  }

  _formatValue (textInput) {
    return parseFloat(
      (textInput || '0').replaceAll(',', '')
    )
  }

  _nearestLowerThousand (amount) {
    const roundedDown = amount >= 2000 ? Math.floor(amount / 1000) * 1000 : 1000

    return this.currencyFormatter.format(roundedDown)
  }
}
