/* global CookieControl */

const initializeCookieControlConfig = (analytics) => {
  const configData = document.getElementById('cookie-control-config-data')

  if (configData !== null && typeof CookieControl !== 'undefined') {
    let loadSegmentOnce = true

    const integrationsRequired = function () {
      const noIntegrations = { integrations: { All: false } }
      const allIntegrations = { integrations: { All: true } }
      const analyticsIntegrations = {
        integrations: {
          All: false,
          'Facebook App Events': true,
          FullStory: true,
          'Google Analytics': true,
          Mixpanel: true
        }
      }
      const marketingIntegrations = {
        integrations: { All: false, 'Facebook Pixel': true }
      }

      const cookieControl = JSON.parse(CookieControl.getCookie('CookieControl')) || {}
      let analyticsAccepted = false
      let marketingAccepted = false

      if (cookieControl.optionalCookies) {
        analyticsAccepted = cookieControl.optionalCookies.analytics === 'accepted'
        marketingAccepted = cookieControl.optionalCookies.marketing === 'accepted'
      } else {
        analyticsAccepted = CookieControl.getCookie('analytics') === 'accepted'
        marketingAccepted = CookieControl.getCookie('marketing') === 'accepted'
      }

      if (analyticsAccepted && marketingAccepted) {
        return allIntegrations
      } else if (analyticsAccepted) {
        return analyticsIntegrations
      } else if (marketingAccepted) {
        return marketingIntegrations
      } else {
        return noIntegrations
      }
    }

    const loadSegment = function () {
      if (loadSegmentOnce && analytics !== undefined) {
        loadSegmentOnce = false
        if (typeof analytics.load === 'function') {
          const segmentEnvironment = configData.getAttribute('data-segment-environment')
          analytics.load(segmentEnvironment, integrationsRequired())
        }
        analytics.page()
      }
    }

    const config = {
      apiKey: '7c7c9ebb698c5beb4226434a8522d65158d48a37',
      product: 'PRO',
      necessaryCookies: ['_tifosy_session', 'tifosy_from_*', 'auto-chat', '__stripe_mid', '__stripe_sid', 'hsfirstvisit', 'ajs_*', '__profilin', '__ar_v4', '__tld__', 'cookietest', 'tifosy-ad', '_tifosy_insights', '_tifosy_territory', 'user_signed_in'],
      consentCookieExpiry: 365,
      onLoad: function () {
        const dismissButton = document.querySelector('#ccc-notify-dismiss')
        const acceptButton = document.querySelector('#ccc-notify-accept')

        if (acceptButton) {
          acceptButton.focus()
        }

        if (dismissButton) {
          dismissButton.style.pointerEvents = 'auto'
          dismissButton.style.visibility = 'visible'
        }
      },
      optionalCookies: [
        {
          name: 'analytics',
          label: configData.getAttribute('data-analytics-label'),
          description: configData.getAttribute('data-analytics-description'),
          cookies: ['_ga', '_gid', '_gat', '__utma', '__utmt', '__utmb', '__utmc', '__utmz', '__utmv', 'fs_mixpanel', 'fs_uid', 'mp_*', 'UULE', 'gsScrollPos-*'],
          onAccept: loadSegment,
          onRevoke: function () { loadSegmentOnce = false }
        }, {
          name: 'marketing',
          label: configData.getAttribute('data-marketing-label'),
          description: configData.getAttribute('data-marketing-description'),
          cookies: ['_bizo_bzid', '_bizo_cksm', '_bizo_np_stats', 'NID', 'SID', 'ad_user_data', 'ad_personalization'],
          onAccept: loadSegment,
          onRevoke: function () { loadSegmentOnce = false }
        }
      ],
      position: 'LEFT',
      theme: 'LIGHT',
      initialState: 'NOTIFY',
      layout: 'SLIDEOUT',
      closeStyle: 'button',
      rejectButton: false,
      branding: {
        fontFamily: 'canada-type-gibson, Helvetica, Arial, sans-serif',
        fontColor: '#FFF',
        fontSizeTitle: '1.4em',
        fontSizeIntro: '1em',
        fontSizeHeaders: '1.2em',
        fontSize: '1.1em',
        backgroundColor: configData.getAttribute('data-primary-color'),
        toggleText: configData.getAttribute('data-primary-color'),
        toggleColor: configData.getAttribute('data-primary-dark-color'),
        toggleBackground: '#EEE',
        buttonIcon: null,
        buttonIconWidth: '64px',
        buttonIconHeight: '64px',
        removeIcon: configData.getAttribute('data-remove-icon') === 'true',
        removeAbout: true,
        acceptText: '#FFF',
        acceptBackground: '#000'
      },
      text: {
        notifyTitle: configData.getAttribute('data-notify-title'),
        notifyDescription: configData.getAttribute('data-notify-description'),
        title: configData.getAttribute('data-cookie-title'),
        intro: configData.getAttribute('data-cookie-intro'),
        necessaryTitle: configData.getAttribute('data-necessary-title'),
        necessaryDescription: configData.getAttribute('data-necessary-description'),
        thirdPartyTitle: configData.getAttribute('data-third-party-title'),
        thirdPartyDescription: configData.getAttribute('data-third-party-description'),
        on: configData.getAttribute('data-on-text'),
        off: configData.getAttribute('data-off-text'),
        accept: configData.getAttribute('data-accept-text'),
        settings: configData.getAttribute('data-settings-text'),
        acceptRecommended: configData.getAttribute('data-accept-recommended-text')
      },
      statement: {
        description: configData.getAttribute('data-statement-description'),
        name: configData.getAttribute('data-statement-name'),
        url: configData.getAttribute('data-statement-url'),
        updated: '25/05/2018' // will always show if updated date is in the future
      }
    }
    CookieControl.load(config)
  }
}

export default initializeCookieControlConfig
